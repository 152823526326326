<template>
  <CRow class>
    <CCol col="12">
      <div class="edit-role-container">
        <CCard class="staking-card edit-role-card">
          <CCardBody @change="isFormChange=true">
            <ValidationObserver ref="observer" @submit.prevent="submitRole()">
            <CForm>
              <CRow>
                <CCol md="6" sm="12">
                  <ValidationProvider :name="$t('EDIT_ROLES_PAGE_FIELD_NAME_VALIDATE_NAME')" rules="required" v-slot="{ errors }">
                      <div>
                        <CInput
                          :label="$t('EDIT_ROLES_PAGE_LABEL_NAME')"
                          v-model="role.name"
                          horizontal
                          class="form-group--custom"
                        />
                        <CRow>
                          <CCol :sm="{offset:'3'}"></CCol>
                          <CCol :sm="9" class="error-msg-wrap">
                            <p class="error-msg">{{errors[0]}}</p>
                          </CCol>
                        </CRow>
                      </div>
                  </ValidationProvider>
                </CCol>
                <CCol md="6" sm="12">
                  <ValidationProvider :name="$t('EDIT_ROLES_PAGE_FIELD_NAME_VALIDATE_LEVEL')" rules="required|min_value:0" v-slot="{ errors }">
                      <div>
                        <CInput
                          :label="$t('EDIT_ROLES_PAGE_LABEL_LEVEL')"
                          v-model="role.level"
                          horizontal
                          class="form-group--custom"
                        />
                        <CRow>
                          <CCol :sm="{offset:'3'}"></CCol>
                          <CCol :sm="9" class="error-msg-wrap">
                            <p class="error-msg">{{errors[0]}}</p>
                          </CCol>
                        </CRow>
                      </div>
                  </ValidationProvider>
                </CCol>
              </CRow>

              <h3>{{$t("EDIT_ROLES_PAGE_TITLE_PERMISSION")}}</h3>
              <ul class="list-group mb-3">
                <li
                  class="list-group-item list-group-item-light"
                  v-for="groupName in Object.keys(groupPermissions)"
                  :key="groupName"
                >
                  <h4>{{$t(groupName)}}</h4>
                  <ul class="group-check-per">
                    <li v-for="per in groupPermissions[groupName]" :key="per.id">
                        <label class="checkbox-label">
                          <input type="checkbox" v-model="per.isChecked">
                          {{$t(`EDIT_ROLES_PAGE_${per.displayName}`)}}
                          <i
                            class="fas fa-exclamation-circle"
                            v-c-tooltip="`${per.description}`"
                            color="primary"
                          />
                        </label>
                    </li>
                  </ul>
                </li>

                <div class="form-actions">
                  <CButton class="btn-save" @click="submitRole()" >
                    {{$t("EDIT_ROLES_PAGE_BUTTON_SAVE")}}
                  </CButton>
                </div>
              </ul>
            </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard> 
      </div>
    </CCol>
  </CRow>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { underscored } from 'underscore.string';

export default {
name: 'EditRoles',
data() {
    return {
      id: '',
      groupPermissions: {},
      roleId: '',
      limit: 999,
      offset: 0,
      isEdit: false,
    };
},
computed: mapState({
  role: state => state.role.roleSelected,
  action: state => state.role.state,
  hasCheckedPermissions() {
    return Object.keys(this.groupPermissions).some(groupName => {
      const permissions = this.groupPermissions[groupName];
      
      return permissions.some(x => x.isChecked);
    });
  }
}),
async mounted() {
  const roleId = this.$route.params.roleId;
  if (roleId){
    this.roleId = roleId;
    this.isEdit = true;
  }
  else {
    this.role.name = '';
    this.role.level = 0;
  }
  this.getPermissions();
},
methods: {
  ...mapActions(['getAction','updateRole']),
  async submitRole(){
    const isValid = await this.$refs.observer.validate();
    if (!isValid) return;
    
    if (this.isEdit){
      this.updateRole();
    }
    else {
      this.addRole();
    }
  },
  async updateRole(){
    let permission_ids = [];
    Object.keys(this.groupPermissions).forEach(groupName => {
      const permissions = this.groupPermissions[groupName];
      permission_ids = permission_ids.concat(permissions.filter(x => x.isChecked).map(x => x.id));
    });

    const params = {
      name: this.role.name,
      level: this.role.level,
      permission_ids: permission_ids
    };
    
    try {
      const res = await this.$http.put(endpoints.updateRole(this.roleId), params);
      this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EDIT_ROLES_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('EDIT_ROLES_PAGE_NOTIFY_SUCCESS_MESSAGE'),
      });
      this.$router.push('/admin/roles');
    } catch (err) {
        this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('EDIT_ROLES_PAGE_NOTIFY_ERROR_TITLE'),
            text: responseHelper.getErrorMessage(err)
        });
    }
  },
  async addRole() {
    let permission_ids = [];
    Object.keys(this.groupPermissions).forEach(groupName => {
      const permissions = this.groupPermissions[groupName];
      permission_ids = permission_ids.concat(permissions.filter(x => x.isChecked).map(x => x.id));
    });

    const params = {
      name: this.role.name,
      level: this.role.level,
      permission_ids: permission_ids
    };
    
    try {
      const res = await this.$http.post(endpoints.addRole, params);
      this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EDIT_ROLES_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('EDIT_ROLES_PAGE_NOTIFY_ADD_ROLE_SUCCESS_MESSAGE'),
      });
      this.$router.push('/admin/roles');
    } catch (err) {
        this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('EDIT_ROLES_PAGE_NOTIFY_ERROR_TITLE'),
            text: responseHelper.getErrorMessage(err)
        });
    }
  },
  async getPermissions(){
    const params = {
      limit: this.limit,
      offset: this.offset,
    };

    const [allPermissionResult, hasPermissionResult] = await Promise.all([
      this.$http.get(endpoints.getPermissionIds, {params}),
      this.roleId ? this.$http.get(endpoints.getRoleHasPermissions(this.roleId)) : [],
    ]);

    const roleHasPermissions = hasPermissionResult.data || [];
    const permissions = allPermissionResult.data.items.map(item => {
      item.isChecked = roleHasPermissions.some(e => e.id === item.id);
      item.displayName = item.name;

      return item;
    });
    const groupPermissions = _.groupBy(permissions,'group_name');
    
    _.forEach(groupPermissions, function(value,key){
      var newKey = `EDIT_ROLE_PAGE_PERMISSION_GROUP_NAME_TITLE_${_.toUpper(underscored(key))}`;
      if (_.includes(_.keys(groupPermissions), key)) {
        groupPermissions[newKey] = value;

        delete groupPermissions[key];
      }
    });

    this.groupPermissions = groupPermissions;
  },
},
};
</script>

<style lang="scss">
.edit-role-container {
  padding-left: 9px;
  @media only screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .edit-role-card .card-body {
    padding: 32px 15px;

    .group-check-per {
      list-style: none;
      padding: 0;
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      h3 {
        width: 100%;
        font-size: 18px;
        // text-transform: uppercase;
        margin-bottom: 15px;
        color: #657187;
        font-size: 18px;
        line-height: 24px;
      }
      li {
        width: 33.33333%;
        margin-bottom: 5px;
        display: flex;
        padding: 0 5px;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        input {
          margin: 5px 5px 0 0;
          position: relative;
          top: 1.5px;
        }
        label {
          width: unset;
          font-size: 14px;
          text-align: left;
          line-height: 22px;
          text-transform: uppercase;
        }
      }
    }
    .form-row {
      margin-top: 1em;
      margin-bottom: 5px;
    }
    .error-msg {
      color: #ff0000;
    }

    .form-actions {
      text-align: right;
      margin-top: 30px;

      .btn-save {
        min-width: 146px;
        height: 36px;
        border-radius: 4px;
        background-color: #3b4b7e;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        border: none;
        outline: none !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          padding-right: 0;
        }
      }
    }
  }
}
</style>